import Swiper from 'swiper/bundle';
import { breakpoints } from '../../utils/breakpoints.js';

/**
 * WalletOverview.
 * @returns {any}
 */
export default function WalletOverview() {
  return {
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`),
    mounted() {
      if (this.matchMobile.matches) {
        const sliderOptions = {
          spaceBetween: 10,
          slidesPerView: 'auto',
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
        };

        this.slider = new Swiper(this.$refs.swiper, sliderOptions);
      }

      window.addEventListener('wallet-loaded', (e) => {
        if (e.detail.total > 0) {
          document.querySelector('#wallet').style.display = 'block';
        }
      });
    },
  };
}

/**
 * WalletOverviewRanking.
 * @param {any} props
 * @returns {object}
 */
export function WalletOverviewRanking(props) {
  return {
    type: props.type, //  'investment_stage' | 'top_sectors' | 'top_regions'
    data: [],
    slideIndex: 0,
    totalSlides: 0,
    loading: true,
    showCard: true,
    noRequest: props?.noRequest ? props.noRequest : false,
    async updateData() {
      this.loading = true;
      const response = await fetch(
        `/wp-json/maddyness/portfolio-statistics?investor_id=${props.investor_id}&type=${this.type}&page=${this.slideIndex}`,
      );
      const data = await response.json();
      this.showCard = data.data_count > 0;
      this.$refs.listing.innerHTML = data.listing;
      this.totalSlides = data.totalSlides;
      this.loading = false;
      const event = new CustomEvent('wallet-loaded', { detail: { total: this.totalSlides } });
      window.dispatchEvent(event);
    },
    prevSlide() {
      if (this.loading) {
        return;
      }

      if (this.slideIndex === 0) {
        return;
      }
      this.slideIndex -= 1;
      this.updateData();
    },
    nextSlide() {
      if (this.loading) {
        return;
      }

      if (this.slideIndex === this.totalSlides - 1) {
        return;
      }
      this.slideIndex += 1;
      this.updateData();
    },
    mounted() {
      if (!this.noRequest) {
        this.updateData();
      }
    },
  };
}
