/**
 * MaddymoneyLineChart.
 * @returns {any}
 */
export default function MaddymoneyLineChart() {
  return {
    type: 'series',
    period: 'monthly',
    data: [],
    chart: null,
    loading: false,
    index: 0,
    async updateChartData() {
      this.loading = true;
      const response = await fetch(
        `/wp-json/maddyness/graphics?type=${this.type}&period=${this.period}`,
      );
      const data = await response.json();
      this.data = data;
      this.redraw();
    },
    mounted() {
      this.resizeSelect();
      this.updateChartData();
    },
    handlePeriodChange() {
      this.resizeSelect();
      setTimeout(() => {
        this.data = [];
        this.updateChartData();
      }, 0);
    },
    resizeSelect() {
      if (!this.$refs.period) {
        return;
      }
      const selectedOption = this.$refs.period.querySelector('option:checked');
      const text = selectedOption ? selectedOption.textContent : '';

      const testSpan = document.createElement('span');
      testSpan.innerHTML = text;
      testSpan.style.visibility = 'hidden';

      this.$refs.period.parentNode.append(testSpan);
      const width = testSpan.offsetWidth + 20;
      testSpan.remove();

      this.$refs.period.style.width = `${width}px`;
    },
    async redraw() {
      const { init } = await import('echarts');
      const data = this.data[this.index];
      const xAxisData = data.map((row) => row.label);
      const serieAData = data.map((row) => row['Série A']).map((item) => item || 0);
      const serieBData = data.map((row) => row['Série B']).map((item) => item || 0);
      const serieCData = data.map((row) => row['Série C']).map((item) => item || 0);

      const lineStackChart = init(this.$refs['line-stack']);

      lineStackChart.setOption({
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['Série A', 'Série B', 'Série C'],
          bottom: 'left',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: { show: false },
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter(value) {
              return `${value / 1000000} M€`;
            },
          },
        },
        series: [
          {
            name: 'Série A',
            type: 'line',
            stack: 'Total',
            data: serieAData,
            smooth: true,
            color: ['#C70D0D'],
            tooltip: {
              valueFormatter(value) {
                const formattedValue = value / 1000000;
                return `${Number.parseFloat(formattedValue).toFixed(1)} M€`;
              },
            },
          },
          {
            name: 'Série B',
            type: 'line',
            stack: 'Total',
            data: serieBData,
            smooth: true,
            color: ['#2BE4CE'],
            tooltip: {
              valueFormatter(value) {
                const formattedValue = value / 1000000;
                return `${Number.parseFloat(formattedValue).toFixed(1)} M€`;
              },
            },
          },
          {
            name: 'Série C',
            type: 'line',
            stack: 'Total',
            data: serieCData,
            smooth: true,
            color: ['#3CEE1F'],
            tooltip: {
              valueFormatter(value) {
                const formattedValue = value / 1000000;
                return `${Number.parseFloat(formattedValue).toFixed(1)} M€`;
              },
            },
          },
        ],
      });

      lineStackChart.on('finished', () => {
        this.loading = false;
      });
    },
    handlePrev() {
      if (this.index >= 1) {
        this.index -= 1;
        this.redraw();
      }
    },
    handleNext() {
      this.index += 1;
      this.redraw();
    },
  };
}
